(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define("Msg", ["jquery"], factory);
	else if(typeof exports === 'object')
		exports["Msg"] = factory(require("jquery"));
	else
		root["Msg"] = factory(root["$"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 